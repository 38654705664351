/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    16/04/2021
 **/
import React from "react";
import PropTypes from "prop-types";

// UI
import { Typography } from "@material-ui/core";

// Styling
import "./SummationComponent.scss";

export const SummationComponent = ({ title, text, image, imageAlt, position, ...props }) => {
	return (
		<div className={`summations ${position}`}>
			<Typography variant="h3" className="title">
				{title}
			</Typography>
			<Typography variant="body1" className="content">
				{text}
			</Typography>
			<img className="icon" src={image} alt={imageAlt} loading="lazy" />
		</div>
	);
};

SummationComponent.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	imageAlt: PropTypes.string.isRequired,
	position: PropTypes.oneOf(["left", "right"]),
};

SummationComponent.defaultProps = {
	title: "Lorem ipsum",
	text:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas id massa velit. Quisque mollis fermentum tortor sit amet fringilla. Duis faucibus lacus lectus, ac placerat tortor dapibus non. Curabitur sed ligula tempus, finibus erat ut, egestas purus. Aliquam est nibh, mattis ac fermentum a, tincidunt et purus. Nulla et mattis lorem. Praesent ante lectus, egestas in dignissim in, fringilla a sapien. Suspendisse quam orci, laoreet nec venenatis sodales, condimentum non risus. In in erat purus. Morbi feugiat lobortis faucibus. Integer gravida vel lorem varius pretium. Etiam eu lacus vehicula, laoreet eros non, tincidunt tellus. Mauris eu ullamcorper ligula, sit amet interdum massa.",
	image: "../assets/logobeeld.svg",
	imageAlt: "Lorem ipsum",
	position: "left",
};

export default SummationComponent;
