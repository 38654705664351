/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    14/04/2021
 **/
import React from "react";
import PropTypes from "prop-types";

// settings
import Urls from "../../settings/ulrs.json";
import Socials from "../../settings/socials.json";
import Partners from "../../settings/partners.json";

// styling
import "./FooterComponent.scss";

export const FooterComponent = ({ translation, ...props }) => {
	const getYear = () => {
		var d = new Date();
		return d.getFullYear();
	};

	return (
		<div className="footer">
			<div className="footer-grid page">
				<ul className="ul-no-dot">
					<li className="footer-link">
						<a className="link-no-underline" href={`${Urls.pricing}`}>
							{translation.footer.pricing}
						</a>
					</li>
					<li className="footer-link">
						<a className="link-no-underline" href={`${Urls.about}`}>
							{translation.footer.about}
						</a>
					</li>
					<li className="footer-link">
						<a className="link-no-underline" href={`${Urls.partner}`}>
							{translation.footer.partner}
						</a>
					</li>
				</ul>
				{/* <ul className="ul-no-dot">
						<li className="footer-link"><a className="link-no-underline" href={`${Urls.faq}`}>{translation.footer.supportCenter}</a></li>
					</ul> */}
				<ul
					className="ul-no-dot"
					style={{
						textAlign: "center",
					}}
				>
					<li className="footer-link">
						<a className="link-no-underline" href={`${Urls.gdpr}`}>
							{translation.footer.gdpr}
						</a>
					</li>
					<li className="footer-link">
						<a className="link-no-underline" href={`${Urls.tos}`}>
							{translation.footer.general}
						</a>
					</li>
					{/* <li className="footer-link hide">
						<a className="link-no-underline" href={`${Urls.cookies}`}>
							{translation.footer.cookies}
						</a>
					</li> */}
					<li className="footer-link">
						<a className="link-no-underline" href={Urls.faq}>
							{translation.footer.faq}
						</a>
					</li>
				</ul>
				{/* <ul className="ul-no-dot">
						<li className="footer-link"><a className="link-no-underline" href={`${Urls.pricing}`}>{translation.footer.pricing}</a></li>
						<li className="footer-link"><a className="link-no-underline" href={`${Urls.about}`}>{translation.footer.about}</a></li>
						<li className="footer-link"><a className="link-no-underline" href={`${Urls.partner}`}>{translation.footer.partner}</a></li>
					</ul> */}
				<div className="footer-grid-socials">
					<a
						className="social-linkdin"
						href={`${Socials.linkedin}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<i className="fab fa-linkedin-in footer-social"></i>
					</a>
					<a
						className="social-facebook"
						href={`${Socials.facebook}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<i className="fab fa-facebook-f footer-social"></i>
					</a>
					<a
						className="social-twitter"
						href={`${Socials.twitter}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<i className="fab fa-twitter footer-social"></i>
					</a>
				</div>
			</div>
			<div className="footer-partners">
				<a //
					href={`${Urls.home}`}
					rel="noopener noreferrer"
					target="_blank"
				>
					<img //
						className="partner-img promatask"
						src="/assets/outline-logo.png"
						alt="Promatask"
						style={{
							maxHeight: "90px",
						}}
					/>
				</a>
				<a //
					href={Partners.startitkbc}
					rel="noopener noreferrer"
					target="_blank"
				>
					<img
						className="partner-img startit"
						src="/assets/startit.webp"
						onError={() => {
							this.onError = null;
							this.src = "/assets/startit.png";
						}}
						alt="Startit@KBC"
					/>
				</a>
			</div>
			<div className="footer-copyright page">
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={process.env.REACT_APP_SCHEDULE_MEETING}
				>
					Promatask BV &copy;{` ${getYear()}`}
				</a>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={process.env.REACT_APP_SCHEDULE_MEETING}
				>
					Nielstraat 14, 3890 Gingelom
				</a>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={process.env.REACT_APP_SCHEDULE_MEETING}
				>
					+32<span style={{ color: "#2E2D2C" }}>9</span>485 67 56 58
				</a>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={process.env.REACT_APP_SCHEDULE_MEETING}
				>
					BTW BE 0750.521.761
				</a>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={process.env.REACT_APP_SCHEDULE_MEETING}
				>
					RPR Antwerpen, Hasselt
				</a>
				<a href="mailto:info@promatask.com">info@promatask.com</a>
			</div>
		</div>
	);
};

FooterComponent.propTypes = {
	translation: PropTypes.object.isRequired,
};

FooterComponent.defaultProps = {
	translation: {
		footer: {
			pricing: "Pricing",
			about: "About",
			partner: "Partner",
			gdpr: "GDPR",
			general: "Terms & Conditions",
			cookies: "Cookies",
			faq: "FAQ",
		},
	},
};

export default FooterComponent;
