/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    17/04/2021
 **/
import React from "react";
import PropTypes from "prop-types";

// UI
import { Typography } from "@material-ui/core";

// Styling
import "./AdvantagesComponent.scss";

export const AdvantagesComponent = ({ icon, title, description, ...props }) => {
	return (
		<div className="advantage">
			<i className={icon}></i>
			<Typography variant="h2" className="title">
				{title}
			</Typography>
			<Typography variant="body1" className="description">
				{description}
			</Typography>
		</div>
	);
};

AdvantagesComponent.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

AdvantagesComponent.defaultProps = {
	icon: "far fa-paper-plane fa-4x advantage-img",
	title: "Title",
	description: "Lorem ipsum ...",
};

export default AdvantagesComponent;
