/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    16/04/2021
 **/
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// UI
import Header from "./components/HeaderComponent";
import Footer from "./components/FooterComponent";
import Urls from "./settings/ulrs.json";

// Styling
import { ThemeProvider } from "@material-ui/core";
import theme from "./theming";

// Translation
import translation from "./language/be-nl/translation.json";

// Pages
import HomePage from "./pages/homePage/HomePage";
import PricingPage from "./pages/pricingPage";
// import InvestorPage from "./pages/InvestorPage";
import AboutPage from "./pages/AboutPage";
import PartnerPage from "./pages/PartnerPage/PartnerPage";
import QuickscanPage from "./pages/quickscanPage";

// Styling
import "./generalStyling.scss";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Header translation={translation} />
				<Switch>
					<Route path={`${Urls.tos}`}>
						<iframe
							className="google-pdf"
							title="Promatask Terms of Service"
							src="https://drive.google.com/file/d/1FiJ1cpm5YB5ElxTYbpQpRWnHGpb4DHCW/preview"
							style={{
								paddingTop: 50,
							}}
						></iframe>
					</Route>
					<Route path={`${Urls.gdpr}`}>
						<iframe
							className="google-pdf"
							title="Promatask Terms of Service"
							src="https://drive.google.com/file/d/1MwBA4AsmLU3_W8_p7uHqMHdszqJ-kXgz/preview"
							style={{
								paddingTop: 50,
							}}
						></iframe>
					</Route>
					<Route path={`${Urls.faq}`}>
						<iframe
							className="google-pdf"
							title="Promatask FAQ"
							src="https://docs.google.com/file/d/1VGzMXHhibjxuWSoVM8Zkyd32GXRbIyWmd4iTHD7-gEM/preview"
							style={{
								paddingTop: 50,
							}}
						></iframe>
					</Route>
					<Route path={`${Urls.pricing}`}>
						<PricingPage translation={translation.pricing_page} />
					</Route>
					{/* <Route path={`${Urls.investors}`}>
						<InvestorPage translation={translation.investor_page} />
					</Route> */}
					{/* <Route path={`${Urls.cookies}`}>
						<List
							title={translation.cookies_page.title}
							content={translation.cookies_page.content}
						/>
					</Route> */}
					<Route path={`${Urls.about}`}>
						<AboutPage translation={translation.about_page} />
					</Route>
					<Route path={`${Urls.partner}`}>
						<PartnerPage translation={translation.partner_page} />
					</Route>
					{/* <Route path={`${Urls.referal}`}>
						<Referal translation={translation.referal_page} />
					</Route> */}
					{/* <Route path={Urls.signup}>
						<Signup translation={translation.signup_page} />
					</Route> */}
					<Route path={Urls.quickscan}>
						<QuickscanPage />
					</Route>
					<Route path={`${Urls.home}`}>
						<HomePage translation={translation} />
					</Route>
					<Route path={`${Urls.notFound}`}>
						<div>
							<h1>Page not found</h1>
						</div>
					</Route>
				</Switch>
				<Footer translation={translation} />
			</Router>
		</ThemeProvider>
	);
}

export default App;
