/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    16/04/2021
 **/
import React from "react";

// urls
import urls from "../../settings/ulrs.json";

// UI
import SummationComponent from "../../components/SummationComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { Typography } from "@material-ui/core";

// Styling
import "./HomePage.scss";
import AdvantagesComponent from "../../components/AdvantagesComponent/AdvantagesComponent";
import SupportComponent from "../../components/SupportComponent/SupportComponent";
import AboutUsQuotesComponent from "../../components/AboutUsQuotesComponent";

export const HomePage = ({ translation, ...props }) => {
	return (
		<>
			<div className="home-intro">
				<div className="home-content page">
					<Typography variant="h1" style={{ whiteSpace: "pre-line" }}>
						{translation.home_page.title}
					</Typography>
					<Typography
						variant="body1"
						style={{
							whiteSpace: "pre-line",
						}}
					>
						{translation.home_page.description}
					</Typography>
					<p id="quickscan" className="quickscan"></p>
				</div>
			</div>
			<div className="advantage-block" id="advantages">
				<a id="chevron" href="#advantages">
					<i className="fas fa-chevron-down fa-3x scrolldown"></i>
				</a>
				<div className="advantages page">
					{translation.home_page.advantages.map((advantage) => (
						<AdvantagesComponent
							key={advantage.title}
							icon={advantage.icon}
							title={advantage.title}
							description={advantage.description}
						/>
					))}
				</div>
			</div>
			<div className="summation-block">
				<div className="page">
					<Typography variant="h2">{translation.home_page.summation.title}</Typography>
					{translation.home_page.summation.list.map((summation, index) => (
						<SummationComponent
							title={summation.title}
							image={summation.icon}
							text={summation.description}
							position={index % 2 ? "left" : "right"}
						/>
					))}
				</div>
			</div>
			<SupportComponent translation={translation.home_page.support} />
			<AboutUsQuotesComponent translation={translation.home_page.about_us} />
			<div className="tryout">
				<div className="page">
					<Typography variant="h2">{translation.home_page.tryout_title}</Typography>
					<Typography variant="body1">
						{translation.home_page.tryout_description}
					</Typography>
					<div className="actions">
						<p id="quickscan2" className="quickscan"></p>
						<ButtonComponent
							link={urls.pricing}
							text={translation.button.calculatePrice}
							color=""
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default HomePage;
