/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    10/05/2021
 **/
import React from "react";

// Styling
import "./quickscanPage.scss";

export const quickscanPage = ({ ...props }) => {
    return (
        <div className="page">
            <iframe title="Is mijn onderneming klaar voor de toekomst" src="https://promatasksurvey.outgrow.us/60727a1535958350a3d90e8b?vHeight=1"
            class="quickscan-page"
            // style={{
            //     maxWidth: "1024px",
            //     marginLeft: "auto",
            //     marginRight: "auto",
            //     height: "90vh",
            // }}
            ></iframe>
        </div>
    )
}

export default quickscanPage;