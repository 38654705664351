import { Typography } from "@material-ui/core";
import React from "react";
import { AboutMeQuotesComponent } from "./AboutMeQuotesComponent";

import "./AboutUsQuotesComponent.scss";

export const AboutUsQuotesComponent = ({ translation, ...props }) => {
	return (
		<div className="about-us page">
			<Typography variant="h2">{translation.title}</Typography>
			<div className="about-me-cards">
				<AboutMeQuotesComponent
					name={translation.tom.name}
					title={translation.tom.title}
					quote={translation.tom.quote}
					image="./assets/about/tom2.jpg"
				/>
				<AboutMeQuotesComponent
					name={translation.jonas.name}
					title={translation.jonas.title}
					quote={translation.jonas.quote}
					image="./assets/about/jonas.jpg"
				/>
				<AboutMeQuotesComponent
					name={translation.tim.name}
					title={translation.tim.title}
					quote={translation.tim.quote}
					image="./assets/about/tim3.jpg"
				/>
			</div>
		</div>
	);
};
