/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    20/04/2021
 **/
import React, { useState } from "react";
import PropType from "prop-types";

// UI
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import ButtonComponent from "../../components/ButtonComponent";

// Styling
import "./PriceCardComponent.scss";

export const PriceCardComponent = ({ invoiceAmount, translation, ...props }) => {
	const [isShowingYear, setIsShowingYear] = useState(false);

	const wantedTranslation = translation.modules.filter(
		(module) => module.value === invoiceAmount
	)[0];

	return (
		<Card className={`module-card`}>
			<CardHeader //
				className="card-header"
				title={translation.cardTitle}
			/>
			<CardContent>
				<ToggleButtonGroup
					value={isShowingYear}
					onChange={(e, newShow) => setIsShowingYear(newShow)}
					exclusive
					className="payment-term"
				>
					<ToggleButton
						className="term-choice"
						value={false}
						style={{ textTransform: "revert" }}
					>
						{translation.monthly}
					</ToggleButton>
					<ToggleButton
						className="term-choice"
						value={true}
						style={{ textTransform: "revert" }}
					>
						{translation.yearly}
					</ToggleButton>
				</ToggleButtonGroup>
				<Typography component="div">
					<div className="card-price">
						<Typography variant="h6">{translation.priceStarting}</Typography>
						<div className="devider">
							<div className="value">
								<sup className="currency">€ </sup>
								<Typography variant="h3">{wantedTranslation.monthly}</Typography>
								<div className={`red-line ${!isShowingYear ? "hidden" : ""}`}></div>
								<Typography variant="body1" className="abbo-duration">
									{translation.perMonth}
								</Typography>
							</div>
							<div className={`value yearly ${!isShowingYear ? "hidden" : ""}`}>
								<sup className="currency">€ </sup>
								<Typography variant="h3">{wantedTranslation.yearly}</Typography>
								<Typography variant="body1" className="abbo-duration">
									{translation.perMonth}
								</Typography>
							</div>
						</div>
					</div>
					<ButtonComponent
						text={translation.tryNow}
						link={process.env.REACT_APP_P_APP_REGISTER}
					/>
					<Typography className="explanation" variant="body2">
						{translation.cardExplanation}
					</Typography>
				</Typography>
			</CardContent>
		</Card>
	);
};

PriceCardComponent.propTypes = {
	onCardClick: PropType.func,
	isShowingYear: PropType.bool,
	translation: PropType.object,
	invoiceAmount: PropType.number.isRequired,
};

PriceCardComponent.defaultProps = {
	isShowingYear: true,
	translation: {
		title: "No Title",
		subHeader: "No Subheader",
		advantages: ["item 1", "item 2", "item 3"],
		isMostlyPicked: false,
	},
};

export default PriceCardComponent;
