/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    20/04/2021
 **/
import React from "react";

// UI
import { Button, Typography } from "@material-ui/core";
import AboutUsComponent from "../../components/AboutUsQuotesComponent";

// Styling
import "./AboutPage.scss";

export const AboutPage = ({ translation, ...props }) => {
	return (
		<>
			<div className="about-page page">
				<Typography variant="body1" className="about-intro">
					{translation.intro}
				</Typography>
				<div className="about-content">
					<div className="col-2">
						<div>
							{translation.content.map((subject) => (
								<div className="alinea">
									<Typography variant="h5">{subject.title}</Typography>
									<Typography variant="body1" className="newlines">
										{subject.alinea}
									</Typography>
								</div>
							))}
						</div>
						<img
							className="about-picture"
							src="./assets/ROP00202_640x960.jpg"
							alt="the 3 founders next to each other"
						/>
					</div>
					<div className="alinea">
						<Typography variant="h5">{translation.logo.title}</Typography>
						<Typography variant="body1">{translation.logo.alinea}</Typography>
						<Button
							className="button download"
							startIcon={<i className="far fa-file-archive size-3x"></i>}
							href="/assets/promatask_resources.zip"
							download
							variant="contained"
							color="primary"
						>
							Download
						</Button>
					</div>
				</div>
			</div>
			<AboutUsComponent translation={translation.about_us} />
		</>
	);
};

export default AboutPage;
