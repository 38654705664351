/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    14/04/2021
 **/
import React from "react";
import PropTypes from "prop-types";

// Settings
import Urls from "../../settings/ulrs.json";

// UI
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import ButtonBase from "@material-ui/core/ButtonBase";

// styling
import "./HeaderComponent.scss";
import { Button } from "@material-ui/core";

export const HeaderComponent = ({ translation, ...props }) => {
	return (
		<div className="header-component">
			<div className="content page">
				<ButtonBase disableRipple className="header-link" href={Urls.home}>
					<span className="image"></span>
				</ButtonBase>
				<Button
					className="anti-hover"
					href={"/about"}
					style={{
						borderRadius: 18,
					}}
				>
					Over ons
				</Button>
				<Button
					className="anti-hover"
					target="_blank"
					href={"https://promatask.blogspot.com/"}
					style={{
						borderRadius: 18,
					}}
				>
					Blog
				</Button>
				<Button
					className="anti-hover"
					href={"/pricing"}
					style={{
						borderRadius: 18,
					}}
				>
					Prijzen
				</Button>
				<div></div>
				<Button
					className="anti-hover"
					href={process.env.REACT_APP_P_APP_REGISTER}
					variant="outlined"
					style={{
						borderRadius: 18,
					}}
				>
					{translation.button.tryNow}
				</Button>
				<Button
					href={process.env.REACT_APP_P_APP_LOGIN}
					variant="contained"
					color="primary"
					style={{
						borderRadius: 18,
					}}
				>
					{translation.button.login}
				</Button>
			</div>
		</div>
	);
};

HeaderComponent.propTypes = {
	translation: PropTypes.object.isRequired,
};

HeaderComponent.defaultProps = {
	translation: {
		button: {
			tryNow: "Try now",
			login: "Login",
		},
	},
};

export default HeaderComponent;
