import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
	typography: {
		fontFamily: ["comfortaa"],
		h2: {
			fontSize: "2rem",
		},
	},
	palette: {
		primary: {
			main: "#2169bc",
		},
		secondary: {
			main: "#F6F6F6", // #E3E3E3 #878787 #2E2D2C
			dark: "#E3E3E3",
			contrastText: "#000",
		},
		// contrastThreshold: 10,
	},
	// overrides: {
	// 	MuiButton: {

	// 	}
	// }
	// 	MuiButton: {
	// 		contained: {
	// 			borderRadius: 50,
	// 			color: "white",
	// 			minWidth: 140,
	// 		},
	// 		containedPrimary: {
	// 			backgroundColor: "#2169bc",
	// 			"&:hover": {
	// 				backgroundColor: "#0092D3",
	// 			},
	// 		},
	// 		outlined: {
	// 			borderRadius: 50,
	// 			minWidth: 120,
	// 		},
	// 	},
	// 	MuiCardHeader: {
	// 		root: {
	// 			textAlign: "center",
	// 			backgroundColor: "#2169bc",
	// 			color: "white",
	// 		},
	// 		subheader: {
	// 			color: "white",
	// 		},
	// 	},
	// 	MuiCardContent: {
	// 		root: {
	// 			textAlign: "center",
	// 		},
	// 	},
	// },
});
