/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    20/04/2021
 **/
import React from "react";

// UI
import { Typography } from "@material-ui/core";

// Styling
import "./PartnerPage.scss";
import ButtonComponent from "../../components/ButtonComponent";

export const PartnerPage = ({ translation, ...props }) => {
	return (
		<div className="partner-page page">
			<Typography variant="h4">{translation.title}</Typography>
			{/* <div className="partner-content"> */}
			<div className="partner-explanation">
				{translation.content.map((alinea) => (
					<div>
						{typeof alinea.title !== "undefined" ? <h4>{alinea.title}</h4> : <></>}
						<Typography variant="body1">{alinea.alinea}</Typography>
					</div>
				))}
				<ButtonComponent
					text={translation.button.schedule}
					link={process.env.REACT_APP_SCHEDULE_MEETING}
				/>
				{/* </div> */}
			</div>
		</div>
	);
};

export default PartnerPage;
