/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    14/04/2021
 **/
import React from "react";
import PropTypes from "prop-types";

// UI
import { Button } from "@material-ui/core";

// Styling
import "./ButtonComponent.scss";

export const ButtonComponent = ({ text, color, link, newTab, ...props }) => {
	return newTab ? (
		<Button
			variant="contained"
			color={color}
			href={link}
			className={`button ${color}`}
			style={{ textTransform: "revert" }}
			target="_blank"
		>
			{text}
		</Button>
	) : (
		<Button
			variant="contained"
			color={color}
			href={link}
			className={`button ${color}`}
			style={{ textTransform: "revert" }}
		>
			{text}
		</Button>
	);
};

ButtonComponent.propTypes = {
	text: PropTypes.string.isRequired,
	color: PropTypes.oneOf(["primary", "secondary"]),
	link: PropTypes.string.isRequired,
	newTab: PropTypes.bool,
};

ButtonComponent.defaultProps = {
	text: "Button",
	color: "primary",
	link: "#",
	newTab: false,
};

export default ButtonComponent;
