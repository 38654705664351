/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    19/04/2021
 **/
import React, { useState } from "react";

// UI
import { Typography, Slider } from "@material-ui/core";
import PriceCardComponent from "../../components/PriceCardComponent/PriceCardComponent";
import ButtonComponent from "../../components/ButtonComponent";

// Styling
import "./PricingPage.scss";

export const PricingPage = ({ translation, ...props }) => {
	const [invoiceAmount, setInvoiceAmount] = useState(10);

	return (
		<div className="pricing-page page">
			<Typography variant="h5" className="pricing-title">
				{translation.title}
			</Typography>
			<Typography variant="body1" className="pricing-subtitle">
				{translation.subTitle}
			</Typography>
			<Typography variant="h5" className="pricing-subtitle" style={{ marginTop: "20px" }}>
				{translation.calculateMyPrice}
			</Typography>
			<Typography variant="body1" className="pricing-intro">
				{translation.intro}
			</Typography>
			<div className="pricing">
				<PriceCardComponent translation={translation} invoiceAmount={invoiceAmount} />
				<div className="amount-selection">
					<Typography variant="h6">{translation.priceSubTitle}</Typography>
					<Slider
						className="slider"
						min={2}
						max={270}
						marks={[
							{
								value: 2,
								label: "2",
							},
							{
								value: 10,
								label: "",
							},
							{
								value: 30,
								label: "",
							},
							{
								value: 90,
								label: "",
							},
							{
								value: 270,
								label: "270",
							},
						]}
						step={null}
						value={invoiceAmount}
						onChange={(event, newValue) => {
							setInvoiceAmount(newValue);
						}}
						valueLabelDisplay="on"
					/>
					<Typography variant="body2" className="more">
						{translation.needMoreInvoices}
					</Typography>
					<ButtonComponent
						color="secondary"
						text={translation.contactUs}
						link="https://calendly.com/-promatask-/inspiratiesessie"
						newTab={true}
					/>
				</div>
				<div className="percentage">
					<div></div>
					<img alt="percentage icon" src="./assets/icons/percentage.png" />
					<div>
						<Typography variant="h5">{translation.percentageTitle}</Typography>
						<Typography variant="body1">{translation.percentageExplanation}</Typography>
					</div>
					<div></div>
				</div>
				<div className="partner-program">
					<Typography variant="h5">{translation.partnerTitle}</Typography>
					<Typography variant="body1">
						<span>{translation.partnerContent}</span>
						<a class="mailto" href="mailto:info@promatask.com">
							info@promatask.com
						</a>{" "}
						<span>{translation.partnerContent2}</span>
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default PricingPage;
