import React from "react";
import PropTypes from "prop-types";

import "./AboutMeQuotesComponent.scss";

export const AboutMeQuotesComponent = ({ image, alt, name, title, quote, ...props }) => {
	return (
		<div className="about-me-card">
			<img src={image} alt={alt} />
			<p className="name">{name}</p>
			<p className="title">{title}</p>
			<p className="quote">{quote}</p>
		</div>
	);
};

AboutMeQuotesComponent.propTypes = {
	image: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	name: PropTypes.string,
	title: PropTypes.string,
	quote: PropTypes.string,
};

AboutMeQuotesComponent.defaultProps = {
	image: "./assets/logobeeld.svg",
	alt: "promatask-logo",
	name: "Promatask medewerker",
	title: "Promatask",
	quote: "Working on the application for self-employed",
};
