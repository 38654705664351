/** _____                           _            _
 * |  __ \                         | |          | |
 * | |__) | __ ___  _ __ ___   __ _| |_ __ _ ___| | __
 * |  ___/ '__/ _ \| '_ ` _ \ / _` | __/ _` / __| |/ /
 * | |   | | | (_) | | | | | | (_| | || (_| \__ \   <
 * |_|   |_|  \___/|_| |_| |_|\__,_|\__\__,_|___/_|\_\
 *
 * Property of Promatask©
 *
 * @author  Tim Van Den Bosch
 * @date    17/04/2021
 **/
import React from "react";
import PropTypes from "prop-types";

// UI
import { Typography } from "@material-ui/core";

// Styling
import "./SupportComponent.scss";

export const SupportComponent = ({ translation, ...props }) => {
	return (
		<div className="support-block">
			<div className="support page">
				<div className="content">
					<Typography variant="body1">{translation}</Typography>
				</div>
				<div className="bol">
					<Typography variant="h4">
						Support<br></br>7/7
					</Typography>
				</div>
			</div>
		</div>
	);
};

SupportComponent.propTypes = {
	translation: PropTypes.string.isRequired,
};

SupportComponent.defaultProps = {
	translation: "Support slogan",
};

export default SupportComponent;
